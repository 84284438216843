import React from 'react';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import Container from 'react-bootstrap/Container';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <h1>Letzte Blog-Beiträge</h1>
          <BlogRoll />
        </Container>
      </Layout>
    );
  };
};
