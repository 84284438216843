import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import BlogRollTemplate, { BlogRollTypeRender } from '../components/BlogRollTemplate';

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "DD. MMMM YYYY", locale: "de")
                tags
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data: BlogRollTypeRender) => <BlogRollTemplate data={data} />}
  />
);
